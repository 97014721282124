/**
 * Variable Overrides.
 *
 * Modify this file to override the Bootstrap Framework variables. You can copy
 * these variables directly from ../bootstrap/less/variables.less to this file.
 */

// Set the proper directory for the Bootstrap Glyphicon font.
@icon-font-path: '../bootstrap/fonts/';


@gray-base:              #000;
@gray-dark:              #444;
@gray:                   #777;
@gray-light:             #989898;
@gray-more-light:        lighten(@gray-base, 90%);
@gray-lighter:           #f7f7f7;


@body-bg: #fff;
@white: #fff;
@dark-blue: #051f44;
@mid-blue: #008fb1;
@blue: #0099CC;

@text-color:            @gray-dark;
@link-color:            @blue;
@link-hover-color:      darken(@link-color, 15%);

@font-family-sans-serif:  'Roboto', Helvetica, Arial, sans-serif;
@font-family-slab:        'Roboto Slab', serif;
@font-family-awesome:  'FontAwesome';


@font-family-base:        @font-family-sans-serif;

@font-size-base:          11.5pt;
@font-size-large:         ceil((@font-size-base * 1.15));
@font-size-small:         ceil((@font-size-base * 0.85));
@font-size-h1:            floor((@font-size-base * 2.8));
@font-size-h2:            floor((@font-size-base * 2.15));
@font-size-h3:            ceil((@font-size-base * 1.7));
@font-size-h4:            ceil((@font-size-base * 1.5)); 
@font-size-h5:            ceil((@font-size-base * 1.25));

@container-large-desktop:      (1440px + @grid-gutter-width);
@screen-lg:                  1500px;
@screen-lg-min:              @screen-lg;
//** Deprecated `@screen-lg-desktop` as of v3.0.1
@screen-lg-desktop:          @screen-lg-min;