@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700);


// Bootstrap library.
@import 'bootstrap';

// Base-theme overrides.
@import 'overrides';

// Base-theme overrides.
@import 'variable-overrides';

// Additional mixins.
@import 'user-mixins';

.object-fit(@fit: fill){
  -o-object-fit: @fit;
     object-fit: @fit;
  font-family: 'object-fit: @{fit}';
}

html, body{
	min-height:100%;
}
a, button{
	.transition(all 300ms ease);
}

h1{ 
    margin:0.5em 0 1em 0;
    padding:0;
    font-family: @font-family-sans-serif;

}
h2, h3, h4, h5{
    margin:1.5em 0 1em 0;
    padding:0;
    font-family: @font-family-sans-serif;
}
#admin-menu{
	font-size:12px;
	display:none;
	@media @tablet{
		display:block;
	}
}
body.admin-menu{
	margin-top:0!important;
	@media @tablet{
		margin-top:29px!important;
	}
}

.overlay{
	display:none;
	&.shadow{
		position:absolute;
		display:block;
		width:100%;
		height:100%;
		background:rgba(0, 0, 0, 0.5);
		z-index:1000;
	}
}
.header{
	.transition(all 300ms ease);
	background:@white;
	color:@blue;
	display:block;
	position:fixed;
	width:calc(~'100% - 40px');
	min-height:100%;
	z-index: 1001;
	left:-100%;
	padding:0.5em 0 0 0;
	&.open{
		left:0;
		top:0;
	}
	&:after{
		content:'✕';
		font-size:16pt;
		width:40px;
		height:40px;
		background:@white;
		position: absolute;
		top:0;
		right:-40px;
		padding:4px 10px;
		z-index:9999;
		.box-shadow(inset 0px 0px 10px rgba(0, 0, 0, 0.3));
		cursor: pointer;
	}
	.container{
		width:100%;
	}
	
	.topmenu{
		
		@media @normal{
			box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
		}
		.logo-block{
			padding:0;
			margin:0.5em 0 2em 0;
			text-align:center;
			img{
				
				max-width:231px;
				height:auto;
			}
		}
		.main-menu-block{
			padding:0;
			.nav{
				padding:0;
				margin:0;
				li{
					display: block;
					a{
						display: block;
						margin:0;
						padding:0.5em 0;
						background:none;
						text-transform: uppercase;
						border-bottom:1px solid @gray-more-light;
						color:@gray-base;
						&:hover{
							text-decoration:none;
							color:@blue;
						}
						&.members{
							padding:8px 12px;
							background:#0099CC;
							border-radius: 4px;
							color:@white;
							
						}
					}
				}

			}
		}
	}
	@media @normal{
		z-index: 10;
		position:relative;
		width:100%;
		min-height:auto;
		left:0;
		background:none;
		color:@gray-base;
		padding:0;
		&:after{
			display:none;
		}
		a{
			color:@blue;
		}
		.container{
				width:970px;
				position: relative;
		}
		
		.topmenu{
			padding:1em 0;
			.logo-block{
				padding:10px 15px 0 0;
				margin:0;
				border-top:none;
				border-bottom:none;
				text-align:left;
				img{
					width:100%
				}
			}
			.main-menu-block{
				padding:13px 0px 0 30px;
				.nav{
					text-align: right;
					li{
						display: inline-block;
						a{
							display: block;
							margin:0;
							padding:0.5em 1.2em 0.5em 0;
							background:none;
							text-transform: none;
							border-bottom:none;

						}
						&.dropdown{
							.dropdown-menu{
								margin-top:-2px;
								position:absolute;
								float:left;
								display: none;
								/*.box-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));*/
								padding:1rem 1.5rem;
								font-size: 11pt;
								a{
									text-transform:none;
									padding:0.4em 2em 0.4em 0;
									&:hover{
										text-decoration: none;
										color: #00b9e5 !important;
									}
								}
							}
							&:hover{
								.dropdown-menu{
									display: block;

								}
							}
						}
					}
				}
			}
		}
	}
	@media @wide{
		.container{
			width:1440px;
		}
		
		.topmenu{
			.logo-block{
				padding:0 15px 0 0;
			}
			.main-menu-block{
				font-size:@font-size-base;
			}
			li{
				a{
					padding:0.5em 2em 0.5em 0;
					&.members{
						color:#fff;
						background:@blue;
						padding:6px 8px;
					}
				}
			}
		}
	}
}

.mobile_header{
		display:block;
		padding:0.5em 15px;
		background:#002F5F;
		color:@white;
		vertical-align: middle;
		position: fixed;
	    top: 0;
	    z-index: 100;
	    width: 100%;
	    .box-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
		@media @normal{
			display:none;
		}
		.menu-btn{
			display: inline-block;
			font-size: 18pt;
			font-weight: normal;
			margin-right:0.5em;
			padding:0.25em;
			border:1px solid rgba(255, 255, 255, 0.33);
			.border-radius(3px);
			width:40px;
			height:40px;
			text-align: center;
			cursor: pointer;
		}
		.header-title{
			display: inline-block;
			font-size: @font-size-large;
			line-height: 1;
			font-weight: 300;
			padding:0.25em 0;
		}
}

.slider{
	margin-top:54px;
	position: relative;
	.md-mainimg{
		img{
			.object-fit(cover);
		}
	}
	@media @wide{
		.md-slide-wrap{
			min-height:510px;
			.md-slide-item{
				min-height:510px;
			}
		}
		.md-mainimg{
			img{
				height:510px;
				.object-fit(cover);
			}
		}
	}
	.md-objects{
		width:100%;
		margin:0 auto;
		.md-object{
			padding:1em;
			font-size: @font-size-base;
			color:@white;
			
			box-sizing: border-box;
			width:100%!important;
			line-height: 1.64;
			
			h2{
				font-weight: 400;
				text-align: center;
				margin:0 0 .6em 0;
				padding:0;
				font-size:@font-size-h4;
				font-family: @font-family-base;
				@media @wide{
					font-size: @font-size-h3;
				}
			}
			h3{
				text-align: center;
				margin:0;
				
				@media @wide{
				
				}
			}
			a{
				height:auto;
				width:auto;
				border:1px solid @white;
				color:@white;
				text-transform: uppercase;
				padding:.8em 1.5em;
				display: inline-block;
				margin-top:1em;
				font-style: normal;
			}
		}
	}
	@media @normal{
		margin-top:0;
		.md-objects{
			width:970px!important;
			.md-object{
				text-align: center;
				font-size:@font-size-large;
				h2{
					font-size: @font-size-h1;
				}
				a{
					margin-top:0;
				}
			}
		}
	}
	@media @wide{
		.md-objects{
			width:1440px!important;
			.md-object{
				font-size:@font-size-h2;
				h2{
					font-size: 1.6em;
					font-weight:500;
					margin:3em 0 .4em 0;
				}
				h3{
					font-size: 1em;
					font-weight:300;
					
				}
				a{
					font-size: @font-size-large;
				}
			}
		}
		.md-slide-wrap{
			min-height:510px!important;
			.md-slide-item{
				min-height:510px!important;
			}
		}
		.md-mainimg{
			img{
				min-height:510px!important;
				top:0 !important;
			}
		}
	}
}

.copyright{
	padding:7px 15px;
	margin:0;
	background:#04154d;
	font-size:@font-size-small;
	color:@white;
	a{
		font-size:@font-size-small;
		color:#0099CC;
	}
}
.footer{
	padding:2em 0;
	margin:0;
	background:#042E6F;
	color:@white;
	border:none;
	position: relative;
	font-size:@font-size-small;
	.nav{
		margin:0;
		padding:0;
		li{
			margin: 0 15px 0 0;
			padding: 0;
			float:left;
			font-size:@font-size-small;
			a{
				margin: 0;
				padding: 0 0 1em 0;
				background:none;
				color:@white;
			}
		}
	}
	a{
		color:@white;
	}
	
	.footer-social{
		.fa{
			width:32px;
			height:32px;
			background:@blue;
			display: inline-block;
			margin-right:10px;
			font-size: @font-size-large;
			text-align: center;
			padding-top:6px;
			&.fa-facebook{
				background:#3b5998;
			}
			&.fa-twitter{
				background:#55acee;
			}
			&.fa-google-plus{
				background:#dd4b39;
			}
			&.fa-linkedin{
				background:#007bb5;
			}
		}
	}
	@media @normal{
		.footer-social{
			.fa{
				display: block;
				margin-bottom:5px;
			}
		}
	}
}


.dropdown-menu{

	padding:5px 10px;
	background: #213554;
	color:#fff;
	border-radius: 2px;
	a{
		color:#fff !important;
	}
}

.breadcrumb{
	display: none;
}
.easy-breadcrumb{
	margin-top:10px;
	background: none;
	font-size:@font-size-small;
	padding:1rem;
}
.front .easy-breadcrumb{
	display: none;
}

.region-content{
	p{
		line-height: 2.4rem;
	}
	img {
		margin-bottom:25px;
	}
	img.file-icon {
		margin-bottom:0;
	}

}
blockquote{
	padding: 9.5pt 15pt;
    margin: 20px 0 20px 15px;
    font-size: @font-size-base;
    border-left: 5px solid #00b9e5;
 	line-height: 2.4rem;
}

.view-footer{
	p{
		clear:both;
	}
	text-align: center;
	margin:10px;
}
#colorbox{
	.node-webform{
		h2{
			margin: 0 0 1em 0;
		}
		.submitted{
			display: none;
		}
		.form-item{
			padding:0;
			@media @tablet{
				&.webform-component--first-name,
				&.webform-component--email{
					padding-right:0.5rem;
				}
				&.webform-component--last-name,
				&.webform-component--phone{
					padding-left:0.5rem;
				}
			}
		}
	}	
	.alert-block{
		display: none;
	}
}
.view-id-amp_south_news{
	.field-name-field-image{
		img{
			width:100%;
			height:240px;
			.object-fit(cover);
		}
	}
}
.views-slideshow-cycle-main-frame-row-item{
	margin:0 auto;
	
}





.footer{
	overflow:hidden;
	margin-top:6rem;
	.container{
		position: relative;
		z-index: 10;
	}
}



.phone-top{
	font-size:@font-size-large;
	background:url(../images/phone.png)  no-repeat 0px 22px;
	padding:20px 0 0 25px;
	margin:0 0 10px 0;
	@media @normal{
		background:url(../images/phone.png)  no-repeat 20px 22px;
		padding:20px 0 0 40px;
		margin:0 0 10px 0;
	}
	font-weight:600;
	a{
		color:#333;
	}
}

.page-title{
	background:url(../images/bluebar.png)  repeat left center;
	h1.page-header{
		font-size:@font-size-h2;
		margin:.8em 0 .4em 12px;

		text-transform: uppercase;
		font-weight:400;
		border:none;
		color:#fff;
	}
} 

table.specs-table {
  border: 1px solid #eee;
  background-color: #fafafa;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
table.specs-table td {
  border: 1px solid #eee;
  padding: 8px 6px;
}

table.specs-table th {
  border: 1px solid #eee;
 padding: 8px 6px;
  color:#fff;
  background:#2a69a0;
  font-size: 13px;
  font-weight:600;

}

table.specs-table tbody td {
  font-size: 13px;
}
table.specs-table tr:nth-child(even) {
  background: #fafafa;
}
table.specs-table thead {
  background: #1C6EA4;
 
  border-bottom: 2px solid #444444;
}
table.specs-table thead th {
  font-size: 13px;

  color: #FFFFFF;
  text-align: left;
  border-left: 2px solid #D0E4F5;
}
table.specs-table thead th:first-child {
  border-left: none;
}

ul{
	li{
		line-height: 2.4rem;
	}
}

.node-products{
	
	.field-name-field-brand{
		margin-bottom:2rem;
		img{
			max-width:150px;
			margin:0;
		}
	}
	.field-name-field-product-code{
		margin:0rem 0 2rem 0;
		padding:0 0 1rem 0 ;
		font-size:@font-size-h3;
		border-bottom:4px solid #FAC900;

	}
	.field-name-field-brochure{
		margin:1rem 0 0 0;
		@media @tablet{
			margin:3rem 0;
		}
		img{
			display:none;
		}
		a{
			color:#fff;
			background: #30A5D3;
			border-radius: 66px;
			padding:10px 14px;
		}
		.file-size{
			display: none;
		}
	}
	&.node-teaser{
		&:nth-child(odd){
		/*background:#eee;*/
		background-image: linear-gradient(to right, #eee, white); 
	}
		.field-name-node-link{
			margin:1rem 0 0 0;
			float:left;
			@media @tablet{
				margin:3rem 0;
				float:left;
			}
			img{
				display:none;
			}
			a{
				color:#fff;
				background: #042E6F;
				border-radius: 66px;
				padding:10px 24px;
				margin:0rem ;
				@media @tablet{
					margin:2rem ;
				}
			}
		}
		.field-name-field-brand{
			width:150px;
			float:left;
			border-bottom:none;
			margin-bottom:0;
			margin-right:10px;
			img{
				margin-bottom:10px !important;
				max-width: 150px;
				float:right;
			}

		}
		.field-name-body{
			clear: both;
			margin: 1rem 0 ;
			padding-top:1.5rem;
			
		}
		.field-name-field-image{
			display: block;
			text-align: center;
			img{
				margin:0 auto;
			}
		}
		.field-name-title{
			border-bottom: 4px solid #FAC900;
		}
		h2{
			clear: both;
			font-size:@font-size-h3;
			padding:1.3rem 0 2rem 0;
			margin:0;
			


		}
	}

} 
article{
	padding:1rem;
}

.page-taxonomy-term{
	.node-products{
		margin:0;
		padding:1rem;
		@media @tablet{
			margin:2rem 1rem;
			padding:2rem;
		}
	}
}
.node-type-products{
	.node-products{
		margin:1rem 0;
		padding:1rem;
	}
}

.hp-categories{
	.view-content{
		.flex-container(row, wrap, center, stretch, flex-start);
		.views-row{
			.flex-element(0, 0, 0, 100%);
			margin:0;
			padding:1rem;
			border:2rem solid #fff;
			img{
				display: inline-block;

			}
			.views-field-description{
				margin:1.5rem 2rem 0;
				clear: both;
			}
			.views-field-name{
				margin:2rem;
				a{
					color:#fff;
					text-transform: uppercase;
					background: #30A5D3;
					border-radius: 66px;
					border:1px solid #30A5D3;
					padding:10px 14px;
					text-align: center;
					display:block;
					&:hover{
						color:#333;
						background: #ffffff;
						text-decoration: none;
						
						border:1px solid #ccc;
					}
				}

			}
			
			@media @tablet{
				.flex-element(0, 0, 0, 50%);
			}
			@media @normal{
				.flex-element(0, 0, 0, 50%);
			}
			@media @wide{
				.flex-element(0, 0, 0, 33.333%);
				.views-field-field-image{
					height:300px;
					text-align: center;
				}

			}
		}
	}

}

#block-webform-client-block-10,
#block-block-15{
	margin:3rem 0;
	.form-actions{
		padding:15px;
	}
}


.page-taxonomy-term-7{
	#block-system-main{
		display:none;
	}
}

.enquiry-form{
	margin-top:2rem;
	position: relative;
	form{
		border-top:3px solid #FAC900;
		display: none;
	}
	&.open{
		form{
			display: block;
		}
		.block-title{
			margin:0 0 1rem 0;

		}
	}
	.block-title{
		cursor: pointer;
		.btn;
		position: absolute;
		margin-top:-6.2rem !important;
		left:170px;
		background: #042E6F;
		border-radius: 66px;
		padding: 10px 14px;
		color:#fff;
		&:hover{
			color:#fff;
		}
	}
	.panel{
		border:none;
		.panel-heading{
			background: none;
			border:none;
			font-weight:600;
			padding: 1rem 0;
		}
		.control-label{
			font-weight:400 !important;
		}
		.panel-body{
			.clearfix;
			padding:1rem 0;
		}
	}
}
.not-logged-in{
	.enquiry-form{
		.block-title{
			position: relative;
			margin-top:0 !important;
			left:0px;
		}
	}
}